<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? "Update" : "Create" }} Quote</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center">
            <b-spinner variant="secondary"></b-spinner>
          </p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'user_id')"
                            label="Staff Member:">
                <user-search
                  v-model="quote.user"
                  :error-feed="errors.errors"
                  :error-key="'user_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="user"
                  @input="userUpdated"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'customer_id')"
                            label="Customer:">
                <customer-search
                  v-model="quote.customer"
                  :error-feed="errors.errors"
                  :error-key="'customer_id'"
                  class="align-self-lg-center mb-2 mb-lg-0 mr-lg-1 text-nowrap w-100"
                  type="customer"
                  @input="customerUpdated"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'quote_date')"
                            label="Quote Date:">
                <b-form-datepicker id="quote_date" v-model="quote.quote_date"
                                   :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"
                                   :disabled="isSaving" :state="validationState(errors.errors, 'quote_date')"
                                   :value="quote.quote_date" class="mb-2" close-button reset-button></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'quote_source')"
                            label="Quote Source:">
                <b-form-select v-model="quote.quote_source" :disabled="isSaving"
                               :state="validationState(errors.errors, 'quote_source')">
                  <b-form-select-option :value="null">Please select a quote source</b-form-select-option>
                  <b-form-select-option value="Web Page">Web Page</b-form-select-option>
                  <b-form-select-option value="Facebook">Facebook</b-form-select-option>
                  <b-form-select-option value="Recommendation">Recommendation</b-form-select-option>
                  <b-form-select-option value="Friends & Family">Friends & Family</b-form-select-option>
                  <b-form-select-option value="Repeat Customer">Repeat Customer</b-form-select-option>
                  <b-form-select-option value="Local">Local</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Quote Uploads -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                         @click.prevent="toggleCollapse('order_uploads')">
                    <b-icon :icon="collapsed['order_uploads'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5">
                    </b-icon>
                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Uploads</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end mb-0" cols="12" md="6">
                    <div class="d-flex flex-column flex-fill ml-4">
                      <b-form-file v-model="quote.documents" drop-placeholder="Drop file here..."
                                   placeholder="Choose a file...">
                      </b-form-file>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                  <b-col v-if="!quote.order_uploads.length" cols="12">
                    <b-col class="text-center" cols="12">
                      <b-card class="py-3 text-center">
                        <p class="mb-4">
                          <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                        </p>
                        <p class="font-weight-normal h5 mb-0 text-dark">There are no uploaded files.</p>
                      </b-card>
                    </b-col>
                  </b-col>
                  <b-col v-if="quote.order_uploads.length" cols="12">
                    <b-card>
                      <b-col v-for="(order_upload, uploadId) in quote.order_uploads" :key="uploadId">
                        <b-card class="mb-4">
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">Document Name:
                              {{ order_upload.document.filename }}
                            </b-col>
                          </b-card-text>
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">Upload Date:
                              {{ moment(order_upload.created_at).format("Do MMM YYYY") }}
                            </b-col>
                          </b-card-text>
                          <b-card-text>
                            <b-col class="d-flex justify-content-start" cols="12" md="12">
                              <b-button v-b-hover.bottom="'View this document.'" class="mr-1 mb-0" variant="secondary">
                                <b-icon icon="eye"/>
                                <a :href="order_upload.document.url" class="text-white" target="_blank">View</a>
                              </b-button>
                            </b-col>
                          </b-card-text>
                        </b-card>
                      </b-col>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <!-- Quote Items -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row mb-4" cols="12" md="8" role="button"
                         @click.prevent="toggleCollapse('order_items')">
                    <b-icon :icon=" collapsed['order_items'] ? 'chevron-right' : 'chevron-down'"
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column justify-content-center flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-2">Items</p>
                    </div>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end mb-2" cols="12" md="6">
                    <b-button v-b-hover.bottom="'Fitting Total'" variant="success">
                      Total Fitting: {{ computedFittingTotal| currencyFormat }}
                    </b-button>
                  </b-col>
                  <b-col class="d-flex flex-column justify-content-end mb-2 " cols="12" md="6">
                    <b-button v-b-hover.bottom="'quote total'" block variant="primary">Total Inc Vat:
                      {{ computedQuoteTotal | currencyFormat }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['order_items']" class="mt-4">
                  <b-col v-if="!quote.order_items.length" cols="12">
                    <hr class="mt-0"/>
                    <b-row>
                      <b-col class="text-center" cols="12">
                        <b-card class="py-3 text-center">
                          <p class="mb-4">
                            <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                          </p>
                          <p class="font-weight-normal h5 mb-0 text-dark">
                            There are no quote items.
                          </p>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col v-if="quote.order_items.length" cols="12">
                    <hr class="mt-0"/>
                    <b-col cols="12">
                      <b-row>
                        <b-col class="d-flex flex-column mb-4">
                          <b-button @click="openDiscountModal">Set Discounts</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="d-flex flex-column mb-4" cols="12">
                          <b-table-simple class="overflow-auto" hover striped>
                            <b-thead>
                              <b-tr>
                                <b-th>Product</b-th>
                                <b-th>Quantity</b-th>
                                <b-th>Price</b-th>
                                <b-th>Total Price</b-th>
                                <b-th>Fitting Cost</b-th>
                              </b-tr>
                            </b-thead>
                            <b-tbody>
                              <b-tr v-for="(item, ind) in quote.order_items" :key="ind"
                                    :class="ind%2 === 0 ? '' : 'grey-bg'">
                                <td>
                                  <p class="mb-0">{{ item.name }}</p>
                                  <small v-if="!(item.product && item.product.range)" class="text-muted">No
                                    Range</small>
                                  <small v-else class="text-muted">Range: {{ item.product.range }}</small>
                                  <p><small v-if="item.measurement_types_id === 1" class="text-muted">
                                    {{ item.length }} X {{ item.width }}</small>
                                    <small v-else-if="item.measurement_types_id === 2" class="text-muted">Packs:
                                      {{ item.quantity }}</small>
                                    <small v-else class="text-muted">Items: {{ item.quantity }}</small>
                                  </p>
                                  <p><small v-if="item.deleted_at" class="text-muted">Deleted
                                    {{ moment(item.deleted_at).format("Do MMM YYYY") }}</small></p>
                                </td>

                                <td v-if="item.measurement_types_id === 1 && !item.deleted_at">{{ item.quantity }}
                                </td>
                                <td v-else>{{ item.quantity }}</td>

                                <!-- Price -->
                                <td>{{ item.sale_price| currencyFormat() }}</td>
                                <td>{{ item.total_price | currencyFormat() }}</td>

                                <!-- Fitting Cost -->
                                <td v-if="item.fitting_required === true">
                                  <div v-if="!item.fitting_cost_exc_vat">
                                    {{ item.fitting_cost_inc_vat| currencyFormat }}
                                  </div>
                                  <div v-else-if="!item.fitting_cost_inc_vat">
                                    {{ item.fitting_cost_exc_vat| currencyFormat }}
                                  </div>
                                  <div v-else>No Fitting Cost</div>
                                </td>
                                <td v-else><small class="text-muted">No Fitting Required</small></td>
                              </b-tr>
                            </b-tbody>
                          </b-table-simple>
                        </b-col>
                      </b-row>
                    </b-col>
                    <div v-for="(item, ind) in quote.order_items" :key="ind" :class="ind%2 == 0 ? '' : 'grey-bg'">
                      <!-- Product Search -->
                      <product-search
                        v-if=" item.product_id || (item.product_id == null && item.name == null)"
                        v-show="!item.deleted_at"
                        v-model="item.product"
                        :display-price="item.sale_price"
                        :error-feed="errors.errors"
                        :error-key="'order_items.' + ind + '.product_id'"
                        :order-item-deleted-at="item.deleted_at"
                        :use-display-price="true"
                        can-have-new-item
                        class="mb-3"
                        show-delete
                        type="order_items"
                        @delete="rmItem(ind)"
                        @input="itemUpdated(ind)">
                        <template #form-area>
                          <!-- Square Meters -->
                          <b-row v-if="item.measurement_types_id === 1">
                            <!-- Quantity Calculations -->
                            <b-col v-if="item.product.variations.length" cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'product_variation_id')"
                                class="mt-4"
                                prepend="Variation">
                                <b-form-select v-model="item.product_variation_id" :disabled="isSaving"
                                               :state="validationState(errors.errors,'order_items.' +ind +'.product_variation_id')"
                                               @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width: {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                              <small
                                class="text-danger">{{ validationInvalidFeedback(errors.errors, `order_items.${ind}.product_variation_id`)
                                }}</small>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.length')"
                                class="mt-4" prepend="Length">
                                <template #append>
                                  <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </template>
                                <b-form-input v-model="item.length" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.length')"
                                              @input="calculateSquareMeters(ind)">
                                </b-form-input>
                              </b-input-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".length") }}
                              </small>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                                class="mt-4"
                                prepend="Price £">
                                <b-form-input v-model="item.sale_price" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                              placeholder="Price Inc VAT"/>
                              </b-input-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                              </small>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                                class="mt-4"
                                prepend="Discount £">
                                <b-form-input v-model="item.discount" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.discount')"/>
                              </b-input-group>
                            </b-col>

                            <b-col class="mt-4" cols="12">
                              <b-card>
                                <div class="d-flex flex-row">
                                  <div class="flex-fill"><h4 class="mb-0">Fitting Details</h4></div>
                                  <div>
                                    <b-form-checkbox v-model="item.fitting_required" :disabled="isSaving"
                                                     :state="validationState(errors.errors,'order_items.' + ind +'.fitting_required')"
                                                     :unchecked-value="false"
                                                     :value="true">
                                      Fitting Required
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <hr v-if="item.fitting_required === true"/>
                                <b-row v-if="item.fitting_required === true">
                                  <b-col cols="12">
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                      class="mt-4"
                                      prepend="Fitting Date">
                                      <b-form-datepicker v-model="item.fitting_date"
                                                         :date-format-options="{year: 'numeric', month: 'numeric', day: 'numeric'}"
                                                         :disabled="isSaving"
                                                         :state="validationState(errors.errors,'order_items.' + ind + '.fitting_date')"
                                                         close-button
                                                         reset-button
                                                         size="sm"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                      }}
                                    </small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                      class="mt-4"
                                      prepend="Fitting Time">
                                      <b-form-input
                                        v-model="item.fitting_time"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' + ind + '.fitting_time')"
                                        placeholder="HH:MM"
                                      />
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                      }}
                                    </small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind +'.fitting_charge')"
                                      class="mt-4"
                                      prepend="Fitting Charge">
                                      <b-form-select
                                        v-model="item.fitting_charge"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' + ind + '.fitting_charge')">
                                        <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                        <b-form-select-option value="Invoice Fitting Charge">Invoice
                                        </b-form-select-option>
                                        <b-form-select-option value="Direct Fitting Charge">Direct
                                        </b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                      }}
                                    </small>
                                    <b-input-group
                                      v-if="item.fitting_charge === 'Invoice Fitting Charge'"
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                      class="mt-4"
                                      prepend="Fitting Cost £">
                                      <b-form-input
                                        v-model="item.fitting_cost_inc_vat"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                        placeholder="Fitting Cost Inc Vat"
                                        @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge === 'Invoice Fitting Charge'" class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                      }}
                                    </small>
                                    <b-input-group v-if=" item.fitting_charge === 'Direct Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                   class="mt-4"
                                                   prepend="Fitting Cost £">
                                      <b-form-input
                                        v-model="item.fitting_cost_exc_vat"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                        placeholder="Fitting Cost Exc Vat"
                                        @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge === 'Direct Fitting Charge'" class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                      }}
                                    </small>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>

                            <b-col cols="12">
                              <b-form-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                                class="mt-4"
                                label="Supplier">
                                <supplier-search
                                  v-model="item.supplier"
                                  :error-feed="errors.errors"
                                  :error-key="'supplier_id'"
                                  type="supplier"
                                  @input="supplierUpdated(ind)"
                                />
                              </b-form-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                              </small>
                            </b-col>

                            <b-col cols="12" lg="12" md="12" xl="4">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                                class="mt-4 w-100">
                                <b-form-checkbox
                                  v-model="item.staircase"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.staircase')">
                                  Staircase Involved
                                </b-form-checkbox>
                              </b-input-group>
                            </b-col>
                          </b-row>
                          <!-- End Square Meters -->

                          <!-- Packs -->
                          <b-row v-else>
                            <b-col v-if="item.product.variations.length" cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'product_variation_id' )"
                                class="mt-4"
                                prepend="Variation">
                                <b-form-select
                                  v-model="item.product_variation_id"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' +ind +'.product_variation_id')"
                                  @change="productVariationUpdated(ind)">
                                  <b-form-select-option :value="null">Please select a variation</b-form-select-option>
                                  <b-form-select-option v-for="variation in item.product.variations"
                                                        :key="`variation${ind}-${variation.id}`" :value="variation.id">
                                    {{ variation.name }} - Width: {{ variation.width }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-input-group>
                            </b-col>
                            <b-col cols="12">
                              <b-form-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.quantity')"
                                :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                class="mt-4">
                                <b-input-group prepend="Quantity">
                                  <b-form-input
                                    v-model="item.quantity"
                                    :disabled="isSaving"
                                    :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                    @input="updateOrderItemTotal(ind)">
                                  </b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                                class="mt-4"
                                prepend="Price £">
                                <b-form-input
                                  v-model="item.sale_price"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                  placeholder="Price Inc VAT"/>
                              </b-input-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                              </small>
                            </b-col>

                            <b-col cols="12" md="6">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                                class="mt-4"
                                prepend="Discount £">
                                <b-form-input
                                  v-model="item.discount"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.discount')"/>
                              </b-input-group>
                            </b-col>

                            <b-col class="mt-4" cols="12">
                              <b-card>
                                <div class="d-flex flex-row">
                                  <div class="flex-fill">
                                    <h4 class="mb-0">Fitting Details</h4>
                                  </div>
                                  <div>
                                    <b-form-checkbox
                                      v-model="item.fitting_required"
                                      :disabled="isSaving"
                                      :state="validationState(errors.errors,'order_items.' +ind +'.fitting_required')"
                                      :unchecked-value="false"
                                      :value="true">
                                      Fitting Required
                                    </b-form-checkbox>
                                  </div>
                                </div>
                                <hr v-if="item.fitting_required === true"/>
                                <b-row v-if="item.fitting_required === true">
                                  <b-col cols="12">
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                      class="mt-4"
                                      prepend="Fitting Date">
                                      <b-form-datepicker
                                        v-model="item.fitting_date"
                                        :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_date')"
                                        close-button
                                        reset-button
                                        size="sm"/>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                      }}
                                    </small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                      class="mt-4"
                                      prepend="Fitting Time">
                                      <b-form-input
                                        v-model="item.fitting_time"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_time')"
                                        placeholder="HH:MM"/>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                      }}
                                    </small>
                                    <b-input-group
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_charge')"
                                      class="mt-4"
                                      prepend="Fitting Charge">
                                      <b-form-select
                                        v-model="item.fitting_charge"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_charge')">
                                        <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                        <b-form-select-option value="Invoice Fitting Charge">Invoice
                                        </b-form-select-option>
                                        <b-form-select-option value="Direct Fitting Charge">Direct
                                        </b-form-select-option>
                                      </b-form-select>
                                    </b-input-group>
                                    <small class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                      }}
                                    </small>
                                    <b-input-group
                                      v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_cost_inc_vat')"
                                      class="mt-4"
                                      prepend="Fitting Cost £">
                                      <b-form-input
                                        v-model="item.fitting_cost_inc_vat"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                        placeholder="Fitting Cost Inc Vat"
                                        @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if=" item.fitting_charge === 'Invoice Fitting Charge'" class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                      }}
                                    </small>
                                    <b-input-group v-if="item.fitting_charge === 'Direct Fitting Charge'"
                                                   :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                   class="mt-4"
                                                   prepend="Fitting Cost £">
                                      <b-form-input
                                        v-model="item.fitting_cost_exc_vat"
                                        :disabled="isSaving"
                                        :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                        placeholder="Fitting Cost Exc Vat"
                                        @input="updateOrderItemTotal(ind)"/>
                                    </b-input-group>
                                    <small v-if="item.fitting_charge === 'Direct Fitting Charge'" class="text-danger">
                                      {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                      }}
                                    </small>
                                  </b-col>
                                </b-row>
                              </b-card>
                            </b-col>

                            <b-col cols="12">
                              <b-form-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                                class="mt-4"
                                label="Supplier">
                                <supplier-search
                                  v-model="item.supplier"
                                  :error-feed="errors.errors"
                                  :error-key="'supplier_id'"
                                  type="supplier"
                                  @input="supplierUpdated(ind)"/>
                              </b-form-group>
                              <small class="text-danger">
                                {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                              </small>
                            </b-col>

                            <b-col cols="12">
                              <b-input-group
                                :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                                class="mt-4 w-100">
                                <b-form-checkbox
                                  v-model="item.staircase"
                                  :disabled="isSaving"
                                  :state="validationState(errors.errors,'order_items.' + ind + '.staircase')">
                                  Staircase Involved
                                </b-form-checkbox>
                              </b-input-group>
                            </b-col>
                          </b-row>
                          <!-- End Packs -->
                        </template>
                      </product-search>
                      <!-- None DB Product -->
                      <b-card v-if="item.product_id == null && item.name" v-show="!item.deleted_at" class="mb-3">
                        <b-row>
                          <b-col>
                            <p class="font-weight-bold mb-0">
                              {{ item.name }}
                              <b-badge variant="primary">£{{ item.sale_price }}</b-badge>
                            </p>
                            <p v-if="item.deleted_at" class="mb-0"><small class="text-danger">Order Item Deleted</small>
                            </p>
                          </b-col>
                          <b-col class="d-flex align-items-center justify-content-end" cols="5">
                            <b-button variant="primary" @click.prevent="deselectItem(ind)">
                              <fa-icon icon="pen"/>
                            </b-button>
                            <b-button class="ml-2" variant="danger" @click="rmItem(ind)">
                              <fa-icon icon="trash"/>
                            </b-button>
                          </b-col>
                        </b-row>
                        <!-- Square Meters -->
                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.measurement_types_id')"
                              label="Measurement Type">
                              <b-form-select v-model="item.measurement_types_id" :disabled="isSaving"
                                             :state="validationState(errors.errors,'order_items.' +ind +'.measurement_types_id')">
                                <b-form-select-option :value="null">Please select a measurement type
                                </b-form-select-option>
                                <b-form-select-option value="1">Square Meters</b-form-select-option>
                                <b-form-select-option value="2">Packs</b-form-select-option>
                              </b-form-select>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row v-if="item.measurement_types_id === true">
                          <!-- Quantity Calculations -->
                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.quantity')"
                              :state="validationState(errors.errors,'order_items.' + ind + '.quantity')" class="mt-4">
                              <b-input-group prepend="Quantity">
                                <template #append>
                                  <b-input-group-text>m<sup>2</sup></b-input-group-text>
                                </template>
                                <b-form-input v-model="item.quantity" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                              @input="updateOrderItemTotal(ind)">
                                </b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.width')"
                              class="mt-4" prepend="Width">
                              <template #append>
                                <b-input-group-text>m</b-input-group-text>
                              </template>
                              <b-form-input v-model="item.width" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.width')"
                                            @input="calculateSquareMeters(ind)">
                              </b-form-input>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".width") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.length')"
                              class="mt-4"
                              prepend="Length">
                              <template #append>
                                <b-input-group-text>m</b-input-group-text>
                              </template>
                              <b-form-input v-model="item.length" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.length')"
                                            @input="calculateSquareMeters(ind)">
                              </b-form-input>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".length") }}
                            </small>
                          </b-col>
                          <!-- End Quantity -->

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                              class="mt-4"
                              prepend="Price £">
                              <b-form-input v-model="item.sale_price" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                            placeholder="Price Inc VAT"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                              class="mt-4" prepend="Discount £">
                              <b-form-input v-model="item.discount" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.discount')"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.color')"
                              class="mt-4" prepend="Color">
                              <b-form-input v-model="item.color" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.color')"
                                            placeholder="Product Color"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".color") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.design')"
                              class="mt-4"
                              prepend="Design">
                              <b-form-input v-model="item.design" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.design')"
                                            placeholder="Product Design"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".design") }}
                            </small>
                          </b-col>

                          <b-col class="mt-4" cols="12">
                            <b-card>
                              <div class="d-flex flex-row">
                                <div class="flex-fill">
                                  <h4 class="mb-0">Fitting Details</h4>
                                </div>
                                <div>
                                  <b-form-checkbox v-model="item.fitting_required" :disabled="isSaving"
                                                   :state="validationState( errors.errors, 'order_items.' + ind +'.fitting_required')"
                                                   :unchecked-value="false"
                                                   :value="true">
                                    Fitting Required
                                  </b-form-checkbox>
                                </div>
                              </div>
                              <hr v-if="item.fitting_required === true"/>
                              <b-row v-if="item.fitting_required === true">
                                <b-col cols="12">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                    class="mt-4"
                                    prepend="Fitting Date">
                                    <b-form-datepicker v-model="item.fitting_date"
                                                       :date-format-options="{year: 'numeric',month: 'numeric',day: 'numeric'}"
                                                       :disabled="isSaving"
                                                       :state="validationState(errors.errors,'order_items.' + ind + '.fitting_date')"
                                                       close-button
                                                       reset-button
                                                       size="sm"/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_time')"
                                    class="mt-4"
                                    prepend="Fitting Time">
                                    <b-form-input v-model="item.fitting_time" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' + ind + '.fitting_time')"
                                                  placeholder="HH:MM"/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_charge')"
                                    class="mt-4"
                                    prepend="Fitting Charge">
                                    <b-form-select v-model="item.fitting_charge" :disabled="isSaving"
                                                   :state="validationState(errors.errors,'order_items.' +ind +'.fitting_charge')">
                                      <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                      <b-form-select-option value="Invoice Fitting Charge">Invoice
                                      </b-form-select-option>
                                      <b-form-select-option value="Direct Fitting Charge">Direct</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                    }}
                                  </small>
                                  <b-input-group v-if="item.fitting_charge === 'Invoice Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                 class="mt-4"
                                                 prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_inc_vat" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_inc_vat')"
                                                  placeholder="Fitting Cost Inc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if="item.fitting_charge === 'Invoice Fitting Charge'" class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                    }}
                                  </small>
                                  <b-input-group v-if="item.fitting_charge === 'Direct Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                 class="mt-4"
                                                 prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_exc_vat" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                  placeholder="Fitting Cost Exc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if="item.fitting_charge === 'Direct Fitting Charge'" class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                    }}
                                  </small>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>

                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.supplier_id')"
                              class="mt-4" label="Supplier">
                              <supplier-search
                                v-model="item.supplier"
                                :error-feed="errors.errors"
                                :error-key="'supplier_id'"
                                type="supplier"
                                @input="supplierUpdated(ind)"/>
                            </b-form-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                              class="mt-4">
                              <b-form-checkbox v-model="item.staircase" :disabled="isSaving"
                                               :state="validationState( errors.errors,'order_items.' + ind + '.staircase')">
                                Staircase Involved
                              </b-form-checkbox>
                            </b-input-group>
                          </b-col>
                        </b-row>

                        <!-- Packs -->
                        <b-row v-else-if="item.measurement_types_id === 2">
                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback=" validationInvalidFeedback(errors.errors,'order_items.' + ind + '.quantity')"
                              :state="validationState(errors.errors,'order_items.' + ind + '.quantity')" class="mt-4">
                              <b-input-group prepend="Quantity">
                                <b-form-input v-model="item.quantity" :disabled="isSaving"
                                              :state="validationState(errors.errors,'order_items.' + ind + '.quantity')"
                                              @input="updateOrderItemTotal(ind)"></b-form-input>
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.sale_price')"
                              class="mt-4" prepend="Price £">
                              <b-form-input v-model="item.sale_price" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.sale_price')"
                                            placeholder="Price Inc VAT"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".sale_price") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.discount')"
                              class="mt-4" prepend="Discount £">
                              <b-form-input v-model="item.discount" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.discount')"/>
                            </b-input-group>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.color')"
                              class="mt-4" prepend="Color">
                              <b-form-input v-model="item.color" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.color')"
                                            placeholder="Product Color"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".color") }}
                            </small>
                          </b-col>

                          <b-col cols="12" md="6">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.design')"
                              class="mt-4" prepend="Design">
                              <b-form-input v-model="item.design" :disabled="isSaving"
                                            :state="validationState(errors.errors,'order_items.' + ind + '.design')"
                                            placeholder="Product Design"/>
                            </b-input-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".design") }}
                            </small>
                          </b-col>

                          <b-col class="mt-4" cols="12">
                            <b-card>
                              <div class="d-flex flex-row">
                                <div class="flex-fill"><h4 class="mb-0">Fitting Details</h4></div>
                                <div>
                                  <b-form-checkbox
                                    v-model="item.fitting_required"
                                    :disabled="isSaving"
                                    :state="validationState(errors.errors,'order_items.' +ind +'.fitting_required')"
                                    :unchecked-value="false" :value="true">Fitting Required
                                  </b-form-checkbox>
                                </div>
                              </div>
                              <hr v-if="item.fitting_required === '1'"/>
                              <b-row v-if="item.fitting_required === '1'">
                                <b-col cols="12">
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_date')"
                                    class="mt-4" prepend="Fitting Date">
                                    <b-form-datepicker v-model="item.fitting_date"
                                                       :date-format-options="{year: 'numeric',month: 'numeric', day: 'numeric'}"
                                                       :disabled="isSaving"
                                                       :state=" validationState( errors.errors,'order_items.' + ind + '.fitting_date' )"
                                                       close-buttonreset-buttonsize="sm"/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_date")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback( errors.errors,'order_items.' + ind + '.fitting_time')"
                                    class="mt-4" prepend="Fitting Time">
                                    <b-form-input v-model="item.fitting_time" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' + ind + '.fitting_time')"
                                                  placeholder="HH:MM"/>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_time")
                                    }}
                                  </small>
                                  <b-input-group
                                    :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.fitting_charge' ) "
                                    class="mt-4" prepend="Fitting Charge">
                                    <b-form-select v-model="item.fitting_charge" :disabled="isSaving"
                                                   :state=" validationState( errors.errors,'order_items.' + ind +'.fitting_charge')">
                                      <b-form-select-option :value="null" default>Choose Charge</b-form-select-option>
                                      <b-form-select-option value="Invoice Fitting Charge">Invoice
                                      </b-form-select-option>
                                      <b-form-select-option value="Direct Fitting Charge">Direct</b-form-select-option>
                                    </b-form-select>
                                  </b-input-group>
                                  <small class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_charge")
                                    }}
                                  </small>
                                  <b-input-group v-if="item.fitting_charge ==='Invoice Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind +'.fitting_cost_inc_vat')"
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_inc_vat" :disabled="isSaving"
                                                  :state="validationState(errors.errors,'order_items.' + ind +'.fitting_cost_inc_vat')"
                                                  placeholder="Fitting Cost Inc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if=" item.fitting_charge ==='Invoice Fitting Charge' "
                                         class="text-danger">{{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_inc_vat")
                                    }}
                                  </small>
                                  <b-input-group v-if="item.fitting_charge ==='Direct Fitting Charge'"
                                                 :invalid-feedback="validationInvalidFeedback( errors.errors,'order_items.' + ind +'.fitting_cost_exc_vat')"
                                                 class="mt-4" prepend="Fitting Cost £">
                                    <b-form-input v-model="item.fitting_cost_exc_vat" :disabled="isSaving"
                                                  :state=" validationState( errors.errors,'order_items.' +ind +'.fitting_cost_exc_vat')"
                                                  placeholder="Fitting Cost Exc Vat"
                                                  @input="updateOrderItemTotal(ind)"/>
                                  </b-input-group>
                                  <small v-if=" item.fitting_charge === 'Direct Fitting Charge' " class="text-danger">
                                    {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".fitting_cost_exc_vat")
                                    }}
                                  </small>
                                </b-col>
                              </b-row>
                            </b-card>
                          </b-col>

                          <b-col cols="12">
                            <b-form-group
                              :invalid-feedback="validationInvalidFeedback( errors.errors,'order_items.' + ind + '.supplier_id')"
                              class="mt-4" label="Supplier">
                              <supplier-search
                                v-model="item.supplier"
                                :error-feed="errors.errors"
                                :error-key="'supplier_id'"
                                type="supplier"
                                @input="supplierUpdated(ind)"/>
                            </b-form-group>
                            <small class="text-danger">
                              {{ validationInvalidFeedback(errors.errors, "order_items." + ind + ".supplier_id") }}
                            </small>
                          </b-col>

                          <b-col cols="12">
                            <b-input-group
                              :invalid-feedback="validationInvalidFeedback(errors.errors,'order_items.' + ind + '.staircase')"
                              class="mt-4">
                              <b-form-checkbox v-model="item.staircase" :disabled="isSaving"
                                               :state="validationState(errors.errors,'order_items.' + ind + '.staircase')">
                                Staircase Involved
                              </b-form-checkbox>
                            </b-input-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </div>
                  </b-col>

                  <b-col class="mt-3" cols="12">
                    <b-button block variant="secondary" @click.prevent="addItem">
                      <fa-icon icon="plus"/>
                      Add Item
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <small class="text-danger">
                {{ validationInvalidFeedback(errors.errors, "order_items") }}
              </small>
            </b-col>
          </b-row>

          <!-- Terms and Conditions -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <div class="d-flex flex-row">
                  <div class="flex-fill"><h4 class="mb-0">Terms and Conditions</h4></div>
                </div>
                <b-row>
                  <b-col cols="12">
                    <div class="d-flex flex-column p-6">
                      <small class="mt-3 mb-3">
                        Fitting is carried out by local self employed
                        specialists and we can arrange for one of them to
                        deliver and fit your flooring. This is a separate
                        agreement between yourself and the fitting team. If
                        you’d like the fitters details to correspond with the
                        fitter directly we can provide these for you. The
                        balance of the fitting cost is payable directly to the
                        fitter at each completed stage of the fitting process.
                        Full details of the terms and conditions are set out and
                        attached with your invoice.
                      </small>
                      <small>
                        Please tick the box if you have read and agree to our
                        privacy policy and the attached
                        <a href="/termsandconditions/North-East-Carpets-Terms-And-Conditions.pdf"
                           style="text-decoration: underline !important" target="_blank">terms and
                          conditions.</a></small>
                      <b-form-checkbox v-model="quote.customer_declaration" class="mt-3" required>I accept the terms and
                        conditions
                      </b-form-checkbox>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <small class="text-danger">
                {{ validationInvalidFeedback(errors.errors, "customer_declaration") }}
              </small>
            </b-col>
          </b-row>

          <!-- Quote Notes -->
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card>
                <b-row>
                  <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                         @click.prevent="toggleCollapse('order_notes')">
                    <b-icon :icon=" collapsed['order_notes']  ? 'chevron-right'  : 'chevron-down' "
                            class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                    <div class="d-flex flex-column flex-fill ml-4">
                      <p class="h4 font-weight-bold mb-0">Quote Notes</p>
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="!collapsed['order_notes']" class="mt-4">
                  <!-- Add New Note Field -->
                  <b-col cols="12">
                    <hr class="mt-0"/>
                    <b-form-group :invalid-feedback=" validationInvalidFeedback(errors.errors, 'newNote') "
                                  label="Add a Note">
                      <b-form-textarea v-model="newNote" :state="validationState(errors.errors, 'newNote')"
                                       placeholder="Insert note here..." size="sm"></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="quote.order_notes.length" cols="12">
                    <hr/>
                    <b-row v-for="(note, noteInd) in quote.order_notes" :key="noteInd">
                      <b-col
                        :invalid-feedback=" validationInvalidFeedback( errors.errors,'order_notes.' + noteInd + '.note' )"
                        cols="12">
                        <b-card class="p-4 mt-3" no-body>
                          <p class="note">{{ note.note }}</p>
                          <hr/>
                          <b-row>
                            <b-col class="d-flex flex-row justify-content-start align-items-center" cols="9">
                              <p class="note-created">Left by: {{ note.user.name }}</p>
                            </b-col>
                            <b-col class="d-flex flex-row justify-content-end align-items-center" cols="3">
                              <p class="note-created text-muted">
                                <FriendlyDate :date="note.created_at"></FriendlyDate>
                              </p>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mb-4">
            <b-col md="4" offset-md="8">
              <b-button block class="align-self-end mb-3" type="submit" variant="primary">
                <b-spinner v-if="isSaving" small></b-spinner>
                <span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-modal id="discountModal" title="Set All Discount" @ok="setDiscountsUsingPercentages">
        <b-row>
          <b-col cols="12">
            <b-input-group class="mb-4" prepend="Discount Percentage">
              <b-form-input v-model="discountPercentageInput" type="text"/>
            </b-input-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-form>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import validation from "../../mixins/validation";
import momentMixin from "../../mixins/momentMixin";
import currentUser from "../../mixins/currentUser";

import UserSearch from "../../components/UserSearch";
import CustomerSearch from "../../components/CustomerSearch";
import ProductSearch from "../../components/ProductSearch";
import SupplierSearch from "../../components/SupplierSearch";
import FriendlyDate from "../../components/FriendlyDate";

export default {
  mixins: [validation, momentMixin, currentUser],
  components: {
    UserSearch,
    CustomerSearch,
    ProductSearch,
    SupplierSearch,
    FriendlyDate
  },
  data() {
    return {
      collapsed: {
        order_items: !this.$route.params.id,
        order_notes: true,
        order_uploads: true
      },

      newNote: null,

      quote: {
        user_id: null,
        user: {},
        customer_id: null,
        customer: {},
        measurement_types_id: null,
        measurement_types: {},
        quote_date: new Date().toISOString().slice(0, 10),
        quote_source: null,
        order_items: [],
        order_notes: [],
        order_uploads: [],
        documents: null,
        sub_total: null,
        vat: null,
        total_price: null,
        customer_declaration: null
      },

      discountPercentageInput: null,
    };
  },
  mounted() {
    if (!this.$route.params.id) {
      this.quote.user = this.currentUser;
      this.quote.user_id = this.currentUser.id;

      if (this.$route.query.customer_id) {
        this.quote.customer_id = this.$route.query.customer_id;
        this.quote.customer.id = this.$route.query.customer_id;
      }
    } else {
      this.fetch(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters("quotes", ["errors", "isLoading", "isSaving", "single"]),

    computedVatTotal() {
      return (
        Number(this.computedQuoteTotal) - Number(this.computedSubTotal)
      ).toFixed(2);
    },
    computedSubTotal() {
      return (Number(this.computedQuoteTotal) / 1.2).toFixed(2);
    },
    computedQuoteTotal() {
      let quoteTotal = 0;
      this.quote.order_items.forEach(item => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (!isNaN(item.sale_price) && !isNaN(item.quantity) && !isNaN(item.fitting_cost_inc_vat)) {
            let itemTotal = Number(item.sale_price) * Number(item.quantity) + Number(item.fitting_cost_inc_vat) - Number(item.discount);
            quoteTotal += Number(itemTotal);
          }
        }
      });
      return Number(quoteTotal).toFixed(2);
    },
    computedFittingTotal() {
      let fittingTotal = 0;
      this.quote.order_items.forEach((item) => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (!isNaN(item.fitting_cost_inc_vat)) {
            fittingTotal += Number(item.fitting_cost_inc_vat);
          }
          if (!isNaN(item.fitting_cost_exc_vat)) {
            fittingTotal += Number(item.fitting_cost_exc_vat);
          }
        }
      });
      return Number(fittingTotal).toFixed(2);
    },
  },
  methods: {
    ...mapActions("quotes", ["fetch", "save"]),

    calculateSquareMeters(ind) {
      let totalQuantity =
        Math.round(
          (Number(this.quote.order_items[ind].width) *
            Number(this.quote.order_items[ind].length) +
            Number.EPSILON) *
          100
        ) / 100;
      this.quote.order_items[ind].quantity = totalQuantity;
      this.updateOrderItemTotal(ind);
      return totalQuantity;
    },

    openDiscountModal() {
      this.$bvModal.show('discountModal');
    },

    setDiscountsUsingPercentages() {
      let discountPercentage = this.discountPercentageInput;

      if (!isNaN(discountPercentage)) {
        if (discountPercentage >= 1 && discountPercentage <= 100) {
          this.quote.order_items.forEach(orderItem => {
            orderItem.discount = (
              (discountPercentage / 100) *
              orderItem.total_price
            ).toFixed(2);
          });
        }
      }
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    deselectItem(ind) {
      this.quote.order_items[ind].product_id = null;
      this.quote.order_items[ind].product_variation_id = null;
      this.quote.order_items[ind].product_variation_name = null;
      this.quote.order_items[ind].name = null;
      this.quote.order_items[ind].product = {};
    },

    submit() {
      let formData = new FormData();

      formData.append("id", this.$route.params.id);
      formData.append("user_id", this.quote.user_id ?? "");
      formData.append("customer_id", this.quote.customer_id ?? "");
      formData.append("quote_date", this.quote.quote_date ?? "");
      formData.append("quote", 1);
      formData.append("quote_source", this.quote.quote_source ?? "");
      formData.append("sale_price", this.quote.sale_price ?? "");
      formData.append("sub_total", this.computedSubTotal ?? "");
      formData.append("vat", this.computedVatTotal ?? "");
      formData.append("total_price", this.computedQuoteTotal ?? "");
      formData.append("quantity", this.quote.order_items.quantity ?? "");
      formData.append("newNote", this.newNote ?? "");
      formData.append("documents", this.quote.documents ?? "");
      formData.append("customer_declaration", this.quote.customer_declaration ? 1 : "");

      this.quote.order_items.forEach((item, itemIndex) => {
        formData.append(`order_items[${itemIndex}][id]`, item.id ?? "");
        formData.append(`order_items[${itemIndex}][product_id]`, item.product_id ?? "");
        formData.append(`order_items[${itemIndex}][product_variation_id]`, item.product_variation_id ?? "");
        formData.append(`order_items[${itemIndex}][product_variation_name]`, item.product_variation_name ?? "");
        formData.append(`order_items[${itemIndex}][product]`, item.product ?? "");
        formData.append(`order_items[${itemIndex}][name]`, item.name ?? "");
        formData.append(`order_items[${itemIndex}][quantity]`, item.quantity ?? "");
        formData.append(`order_items[${itemIndex}][width]`, item.width ?? "");
        formData.append(`order_items[${itemIndex}][length]`, item.length ?? "");
        formData.append(`order_items[${itemIndex}][measurement_types_id]`, item.measurement_types_id ?? "");

        formData.append(`order_items[${itemIndex}][cost_price]`, item.cost_price === "" || item.cost_price == null ? "" : !isNaN(item.cost_price) ? Number(item.cost_price).toFixed(2) : item.cost_price);
        formData.append(`order_items[${itemIndex}][sale_price]`, item.sale_price === "" || item.sale_price == null ? "" : !isNaN(item.sale_price) ? Number(item.sale_price).toFixed(2) : item.sale_price);
        formData.append(`order_items[${itemIndex}][sub_total]`, item.sub_total === "" || item.sub_total == null ? "" : !isNaN(item.sub_total) ? Number(item.sub_total).toFixed(2) : item.sub_total);
        formData.append(`order_items[${itemIndex}][vat]`, item.vat === "" || item.vat == null ? "" : !isNaN(item.vat) ? Number(item.vat).toFixed(2) : item.vat);
        formData.append(`order_items[${itemIndex}][total_price]`, item.total_price === "" || item.total_price == null ? "" : !isNaN(item.total_price) ? Number(item.total_price).toFixed(2) : item.total_price);
        formData.append(`order_items[${itemIndex}][fitting_cost_inc_vat]`, item.fitting_cost_inc_vat === "" || item.fitting_cost_inc_vat == null ? "" : !isNaN(item.fitting_cost_inc_vat) ? Number(item.fitting_cost_inc_vat).toFixed(2) : item.fitting_cost_inc_vat);
        formData.append(`order_items[${itemIndex}][fitting_cost_exc_vat]`, item.fitting_cost_exc_vat === "" || item.fitting_cost_exc_vat == null ? "" : !isNaN(item.fitting_cost_exc_vat) ? Number(item.fitting_cost_exc_vat).toFixed(2) : item.fitting_cost_exc_vat);

        formData.append(`order_items[${itemIndex}][discount]`, item.discount ?? "");
        formData.append(`order_items[${itemIndex}][staircase]`, item.staircase ?? "");
        formData.append(`order_items[${itemIndex}][fitting_required]`, item.fitting_required);
        formData.append(`order_items[${itemIndex}][fitting_date]`, item.fitting_date ?? "");
        formData.append(`order_items[${itemIndex}][fitting_time]`, item.fitting_time ?? "");
        formData.append(`order_items[${itemIndex}][fitting_charge]`, item.fitting_charge ?? "");
        formData.append(`order_items[${itemIndex}][supplier_id]`, item.supplier_id ?? "");
        formData.append(`order_items[${itemIndex}][color]`, item.color ?? "");
        formData.append(`order_items[${itemIndex}][fitter_color]`, item.fitter_color ?? "#000000");
        formData.append(`order_items[${itemIndex}][design]`, item.design ?? "");
      });

      this.save({id: this.$route.params.id, data: formData}).then(
        response => {
          if ([200, 201].includes(response.status))
            this.$router.push({
              name: "dash.quotes.list",
              params: {
                notifications: [
                  {
                    variant: "success",
                    title: `Quote #${response.data.id}`,
                    subtitle: `${response.data.customer.name}`,
                    text: `You have ${!this.$route.params.id ? "created a new" : "updated an existing"} quote.`,
                    timeout: 5000
                  }
                ]
              }
            });
        }
      );
    },

    userUpdated() {
      this.quote.user_id = this.quote.user.id;
    },

    customerUpdated() {
      this.quote.customer_id = this.quote.customer.id;
    },

    supplierUpdated(ind) {
      this.quote.order_items[ind].supplier_id = this.quote.order_items[
        ind
        ].supplier.id;
    },

    itemUpdated(ind) {
      this.quote.order_items[ind].product_id = this.quote.order_items[ind].product.id;
      this.quote.order_items[ind].name = this.quote.order_items[ind].product.name;
      this.quote.order_items[ind].sale_price = this.quote.order_items[ind].product.sale_price;
      this.quote.order_items[ind].cost_price = this.quote.order_items[ind].product.cost_price;
      this.quote.order_items[ind].sub_total = this.quote.order_items[ind].sale_price * this.quote.order_items[ind].quantity;
      this.quote.order_items[ind].supplier_id = this.quote.order_items[ind].product.supplier.id;
      this.quote.order_items[ind].supplier = {id: this.quote.order_items[ind].product.supplier_id};
      this.quote.order_items[ind].measurement_types_id = this.quote.order_items[ind].product.measurement_types_id;
      this.updateOrderItemTotal(ind);
      this.quote.order_items[ind].vat = this.quote.order_items[ind].total_price - this.quote.order_items[ind].sale_price;
    },

    productVariationUpdated(ind) {
      let productVariation = this.quote.order_items[ind].product.variations.find(variation => {
        return this.quote.order_items[ind].product_variation_id === variation.id;
      });

      if (productVariation) {
        this.quote.order_items[ind].product_variation_name = productVariation.name;
        this.quote.order_items[ind].width = productVariation.width;
      }

      this.updateOrderItemTotal(ind);
    },

    addItem() {
      this.quote.order_items.push({
        id: null,
        product_id: null,
        product: {id: null},
        name: null,
        product_variation_id: null,
        product_variation_name: null,
        quantity: 1,
        width: 0,
        length: 0,
        measurement_types_id: null,
        cost_price: null,
        sale_price: null,
        sub_total: null,
        vat: null,
        total_price: null,
        discount: 0,
        staircase: 0,
        fitting_required: 0,
        fitting_date: null,
        fitting_time: null,
        fitter_color: null,
        fitting_charge: null,
        fitting_cost_inc_vat: null,
        fitting_cost_exc_vat: null,
        supplier: {id: null},
        color: null,
        design: null
      });
    },
    rmItem(ind) {
      this.quote.order_items.splice(ind, 1);
    },

    updateOrderItemTotal(ind) {
      this.quote.order_items[ind].total_price = 0;
      if (
        Number(this.quote.order_items[ind].sale_price) &&
        Number(this.quote.order_items[ind].quantity)
      ) {
        let totalPrice =
          Number(this.quote.order_items[ind].sale_price) *
          Number(this.quote.order_items[ind].quantity);
        if (this.quote.order_items[ind].fitting_required) {
          switch (this.quote.order_items[ind].fitting_charge) {
            case "Invoice Fitting Charge":
              if (this.quote.order_items[ind].fitting_cost_inc_vat > 0) {
                totalPrice += Number(
                  this.quote.order_items[ind].fitting_cost_inc_vat
                );
              }
              break;
          }
        }
        if (Number(this.quote.order_items[ind].discount)) {
          totalPrice -= this.quote.order_items[ind].discount;
        }
        this.quote.order_items[ind].total_price = totalPrice;
      }
    }
  },
  watch: {
    single() {
      this.quote = this.single;
      this.quote.customer_declaration = Boolean(
        this.quote.customer_declaration
      );
      this.customer = this.single.customer;
    },
    currentUser() {
      this.quote.user = this.currentUser;
      this.quote.user_id = this.currentUser.id;
    }
  },
  filters: {
    currencyFormat: v =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP"
      }).format(v)
  }
};
</script>

<style lang="scss">
.grey-bg .search .card {
  background-color: rgba(0, 0, 0, 0.05);
}

.note {
  font-size: 1rem;
}

.note-created {
  font-size: 0.9rem;
  margin: 0;
}
</style>
