<template>
  <b-container>
    <ResourceList :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
                  :search-query="$route.query.query" can-delete
                  resource="quote" soft-deletes title="Ageing Quotes"
                  @paginate="onPaginate" @search="onSearch" @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary">
              <router-link :to="{ name: 'dash.quotes.update', params: { id: item.id } }" class="text-dark">
                {{ item.customer.name }}
              </router-link>
            </p>
            <p class="align-self-end mb-1 text-muted">Last Update:
              <FriendlyDate :date="item.updated_at"/>
            </p>
          </div>
          <div class="d-flex flex-row flex-fill mb-0 mb-md-1">
            <p class="mb-0">Order ID: {{ item.id }}</p>
          </div>
          <div class="d-none d-md-flex flex-row">
            <b-button v-b-popover.hover.bottom="'View more information about this quote.'"
                      :to="{ name: 'dash.quotes.view', params: { id: item.id } }" class="mr-1 mb-0"
                      size="sm"
                      variant="secondary">
              <b-icon icon="file-check"/>
              View
            </b-button>
            <b-button v-b-popover.hover.bottom="'Edit this quote.'"
                      :to="{ name: 'dash.quotes.update', params: { id: item.id } }" class="mr-1 mb-0" size="sm"
                      variant="primary">
              <b-icon icon="file-check"/>
              Edit
            </b-button>
            <b-button v-b-popover.hover.bottom="'Convert this quote to order.'" class="mr-1 mb-0" size="sm"
                      variant="info"
                      @click="makeOrder(item.id)">
              <b-icon icon="file-check" type="submit"/>
              Convert to Order
            </b-button>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList"
import currentUser from "../../mixins/currentUser"
import {mapActions, mapGetters} from "vuex"
import FriendlyDate from "../../components/FriendlyDate"

export default {
  mixins: [currentUser],
  created() {
    this.fetchManyAgeing(this.$route.query)
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("quotes", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("quotes", ["batchDelete", "fetchManyAgeing", "doMakeOrder"]),

    async onDeleteMany({ids, modalId}) {
      await this.batchDelete({
        ids
      })
      this.$bvModal.hide(modalId)
      await this.fetchManyAgeing(this.$route.query)
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        this.$router.push({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page
          }
        })
        await this.fetchManyAgeing({
          ...this.$route.query,
          page
        })
      }
    },
    async onSearch(query) {
      if (this.$route.query.query !== query) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            query
          }
        })
        await this.fetchManyAgeing({
          ...this.$route.query,
          query
        })
      }
    },
    makeOrder(quoteId) {
      this.doMakeOrder({...this.$route.query, quoteId}).then(response => {
      })
    }
  }
}
</script>
<style></style>
