<template>
  <b-container>
    <ResourceList
      :can-delete="hasAnyPermission(['delete-quotes'])" :data="data"
      :is-deleting="isDeleting"
      :is-loading="isLoading"
      :pagination="pagination"
      :search-query="$route.query.query"
      create-to="dash.quotes.create"
      resource="quote"
      soft-deletes
      title="Quotes"
      @paginate="onPaginate"
      @search="onSearch"
      @delete-many="onDeleteMany">
      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 d-flex flex-column flex-fill mb-1 text-primary">
              <router-link :to="{ name: 'dash.quotes.view', params: { id: item.id } }" class="text-dark">#{{ item.id }}
                {{ item.customer.name }}
              </router-link>
              <small class="mb-0 text-muted">{{ item.customer.email }}</small>
              <small class="mb-0 text-muted d-flex flex-column">
                <span>{{ item.customer.invoice_address_line_1 }}</span>
                <span>{{ item.customer.invoice_address_line_2 }}</span>
                <span>{{ item.customer.invoice_county }}</span>
                <span>{{ item.customer.invoice_postcode }}</span>
              </small>
            </p>
            <p class="align-self-end mb-1 text-muted">
              <FriendlyDate :date="item.created_at"/>
            </p>
          </div>
          <div class="d-none d-md-flex flex-row mt-1">
            <b-button v-b-popover.bottom="'View more information about this quote.'"
                      :to="{ name: 'dash.quotes.view', params: { id: item.id } }"
                      class="mr-1 mb-0"
                      size="sm"
                      variant="primary">
              <b-icon icon="eye"/>
              View
            </b-button>
            <b-button
              v-b-popover.bottom="'Edit this quote.'"
              :to="{ name: 'dash.quotes.update', params: { id: item.id } }"
              class="mr-1 mb-0"
              size="sm"
              variant="secondary">
              <b-icon icon="pen"/>
              Edit
            </b-button>
            <b-button
              v-b-popover.bottom="'Convert this quote to order.'"
              class="mr-1 mb-0"
              size="sm"
              variant="secondary"
              @click="makeOrder(item.id)">
              <b-icon icon="file-check" type="submit"/>
              Convert to Order
            </b-button>

            <b-dropdown right size="sm" text="Customer Quote">
              <b-dropdown-item :href="`/pdf/quotes/customer-quote/${item.id}`">Download Customer Quote
              </b-dropdown-item>
              <b-dropdown-item-button @click="sendCustomerQuote(item.id)">Send Customer Quote</b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
      </template>
    </ResourceList>
  </b-container>
</template>
<script>
import ResourceList from "../../components/ResourceList";
import currentUser from "../../mixins/currentUser";
import {mapActions, mapGetters} from "vuex";
import momentMixin from "../../mixins/momentMixin";
import FriendlyDate from "../../components/FriendlyDate";

export default {
  mixins: [currentUser, momentMixin],
  created() {
    this.fetchMany(this.$route.query);
  },
  components: {
    ResourceList,
    FriendlyDate
  },
  computed: {
    ...mapGetters("authentication", ["hasAnyPermission"]),
    ...mapGetters("quotes", ["data", "isDeleting", "isLoading", "pagination"])
  },
  methods: {
    ...mapActions("quotes", ["deleteMany", "fetchMany", "doMakeOrder"]),

    async onDeleteMany({ids, modalId}) {
      await this.deleteMany({ids});
      this.$bvModal.hide(modalId);
      await this.fetchMany(this.$route.query);
    },
    async onPaginate(page) {
      if (this.$route.query.page !== page) {
        await this.$router.replace({name: this.$route.name, query: {...this.$route.query, page}}).catch(() => {
        });
        await this.fetchMany({...this.$route.query, page}).catch(() => {
        });
      }
    },
    async onSearch(query) {
      if (this.$route.query.page !== query) {
        const path = {name: this.$route.name, query: {...this.$route.query, query}};
        await this.$router.push(path).catch(() => {
        });
      }
      await this.fetchMany({...this.$route.query, query}).catch(() => {
      })
    },


    sendCustomerQuote(quoteId) {
      window.axios
        .post(`/quotes/customer-quote/send/${quoteId}`)
        .then(() => {
          this.$root.$emit("card-notification", {
            variant: "success",
            title: `Customer Quote Sent.`,
            text: `You have sent the Customer Quote for Quote #${quoteId}`,
            timeout: 5000
          });
        });
    },

    makeOrder(quoteId) {
      this.$router.push({
        name: "dash.orders.update",
        params: {id: quoteId}
      });
    }
  }
};
</script>
