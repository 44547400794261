import pagination from '../../utils/pagination'
import {
  CLEAR_ERRORS,
  EMPTY_QUOTES,
  QUOTES_BATCH_DELETE_FAILURE,
  QUOTES_BATCH_DELETE_REQUEST,
  QUOTES_BATCH_DELETE_SUCCESS,
  QUOTES_CANCEL_FAILURE,
  QUOTES_CANCEL_REQUEST,
  QUOTES_CANCEL_SUCCESS,
  QUOTES_FETCH_FAILURE,
  QUOTES_FETCH_MANY_FAILURE,
  QUOTES_FETCH_MANY_REQUEST,
  QUOTES_FETCH_MANY_SUCCESS,
  QUOTES_FETCH_REQUEST,
  QUOTES_FETCH_SUCCESS,
  QUOTES_MAKE_ORDER_FAILURE,
  QUOTES_MAKE_ORDER_REQUEST,
  QUOTES_MAKE_ORDER_SUCCESS,
  QUOTES_SAVE_FAILURE,
  QUOTES_SAVE_REQUEST,
  QUOTES_SAVE_SUCCESS
} from './types'

export default {
  [QUOTES_BATCH_DELETE_REQUEST](state) {
    state.deleting = true
  },
  [QUOTES_BATCH_DELETE_SUCCESS](state) {
    state.deleting = false
  },
  [QUOTES_BATCH_DELETE_FAILURE](state) {
    state.deleting = false
  },
  [QUOTES_FETCH_MANY_REQUEST](state) {
    state.data = []
    state.loading = true
  },
  [QUOTES_FETCH_MANY_SUCCESS](state, {data}) {
    state.data = data.data
    state.page = pagination(data)
    state.loading = false
  },
  [QUOTES_FETCH_MANY_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [QUOTES_FETCH_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [QUOTES_FETCH_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [QUOTES_FETCH_FAILURE](state) {
    state.loading = false
  },
  [QUOTES_SAVE_REQUEST](state) {
    state.data = []
    state.saving = true
  },
  [QUOTES_SAVE_SUCCESS](state) {
    state.saving = false
  },
  [QUOTES_SAVE_FAILURE](state, {
    validationErrors
  }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [QUOTES_MAKE_ORDER_REQUEST](state) {
    state.saving = true
  },
  [QUOTES_MAKE_ORDER_SUCCESS](state) {
    state.saving = false
  },
  [QUOTES_MAKE_ORDER_FAILURE](state, {
    validationErrors
  }) {
    state.validationErrors = validationErrors || {}
    state.saving = false
  },
  [QUOTES_CANCEL_REQUEST](state) {
    state.single = {}
    state.loading = true
  },
  [QUOTES_CANCEL_SUCCESS](state, {data}) {
    state.single = data
    state.loading = false
  },
  [QUOTES_CANCEL_FAILURE](state, {validationErrors}) {
    state.validationErrors = validationErrors || {}
    state.loading = false
  },
  [EMPTY_QUOTES](state) {
    state.data = []
    state.loading = false
    state.saving = false
  },
  [CLEAR_ERRORS](state) {
    state.validationErrors = []
    state.loading = false
    state.saving = false
  },
}
