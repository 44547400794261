import {
  CLEAR_ERRORS,
  EMPTY_QUOTES,
  QUOTES_BATCH_DELETE_FAILURE,
  QUOTES_BATCH_DELETE_REQUEST,
  QUOTES_BATCH_DELETE_SUCCESS,
  QUOTES_CANCEL_FAILURE,
  QUOTES_CANCEL_REQUEST,
  QUOTES_CANCEL_SUCCESS,
  QUOTES_FETCH_FAILURE,
  QUOTES_FETCH_MANY_FAILURE,
  QUOTES_FETCH_MANY_REQUEST,
  QUOTES_FETCH_MANY_SUCCESS,
  QUOTES_FETCH_REQUEST,
  QUOTES_FETCH_SUCCESS,
  QUOTES_MAKE_ORDER_FAILURE,
  QUOTES_MAKE_ORDER_REQUEST,
  QUOTES_MAKE_ORDER_SUCCESS,
  QUOTES_SAVE_FAILURE,
  QUOTES_SAVE_REQUEST,
  QUOTES_SAVE_SUCCESS
} from "./types";

export default {
  deleteMany({commit}, {ids = []}) {
    commit(QUOTES_BATCH_DELETE_REQUEST);
    return window.axios
      .post("/quotes", {_method: "DELETE", ids})
      .then(response => {
        commit(QUOTES_BATCH_DELETE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(QUOTES_BATCH_DELETE_FAILURE, error);
        throw error;
      });
  },
  fetchMany({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(QUOTES_FETCH_MANY_REQUEST);
    return window.axios
      .get("/quotes", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(QUOTES_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(QUOTES_FETCH_MANY_FAILURE, error);
        throw error;
      });
  },
  fetch({commit}, id) {
    commit(QUOTES_FETCH_REQUEST);
    return window.axios
      .get(`/quotes/${id}`)
      .then(response => {
        commit(QUOTES_FETCH_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(QUOTES_FETCH_FAILURE, error);
        throw error;
      });
  },
  save({commit}, {id = null, data}) {
    const path = id ? `/quotes/${id}?_method=PUT` : "/quotes";
    const method = "post";

    commit(QUOTES_SAVE_REQUEST);
    return window.axios[method](path, data)
      .then(response => {
        commit(QUOTES_SAVE_SUCCESS, response);
        return response;
      })
      .catch(error => {
        if (error.response.status === 422) {
          commit(QUOTES_SAVE_FAILURE, {
            validationErrors: error.response.data
          });
        } else {
          commit(QUOTES_SAVE_FAILURE, {
            error
          });
        }
        throw error;
      });
  },
  doMakeOrder({commit, dispatch}, {query = "", quoteId}) {
    const path = `/quotes/make-order/${quoteId}`;

    commit(QUOTES_MAKE_ORDER_REQUEST);
    return window.axios
      .get(path)
      .then(response => {
        commit(QUOTES_MAKE_ORDER_SUCCESS, {response});
        dispatch("fetchMany", query);
        return response;
      })
      .catch(error => {
        if (error.response.status === 422) {
          commit(QUOTES_MAKE_ORDER_FAILURE, {
            validationErrors: error.response.data
          });
        } else {
          commit(QUOTES_MAKE_ORDER_FAILURE, {error});
        }
        throw error;
      });
  },
  empty({commit}) {
    commit(EMPTY_QUOTES);
  },
  clearErrors({commit}) {
    commit(CLEAR_ERRORS);
  },
  cancelQuote({commit}, quoteId) {
    commit(QUOTES_CANCEL_REQUEST);
    return window.axios.post(`/quotes/cancel/${quoteId}`)
      .then(response => {
        commit(QUOTES_CANCEL_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(QUOTES_CANCEL_FAILURE, error);
        throw error;
      })
  },
  fetchManyAgeing({commit}, {query = "", page = 1, extraParams = {}}) {
    commit(QUOTES_FETCH_MANY_REQUEST);
    return window.axios
      .get("/quotes/ageing", {params: {query, page, ...extraParams}})
      .then(response => {
        commit(QUOTES_FETCH_MANY_SUCCESS, response);
        return response;
      })
      .catch(error => {
        commit(QUOTES_FETCH_MANY_FAILURE, error);
        throw error;
      });
  }
};
