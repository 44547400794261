export const QUOTES_BATCH_DELETE_REQUEST = 'QUOTES_BATCH_DELETE_REQUEST'
export const QUOTES_BATCH_DELETE_SUCCESS = 'QUOTES_BATCH_DELETE_SUCCESS'
export const QUOTES_BATCH_DELETE_FAILURE = 'QUOTES_BATCH_DELETE_FAILURE'

export const QUOTES_FETCH_MANY_REQUEST = 'QUOTES_FETCH_MANY_REQUEST'
export const QUOTES_FETCH_MANY_SUCCESS = 'QUOTES_FETCH_MANY_SUCCESS'
export const QUOTES_FETCH_MANY_FAILURE = 'QUOTES_FETCH_MANY_FAILURE'

export const QUOTES_FETCH_REQUEST = 'QUOTES_FETCH_REQUEST'
export const QUOTES_FETCH_SUCCESS = 'QUOTES_FETCH_SUCCESS'
export const QUOTES_FETCH_FAILURE = 'QUOTES_FETCH_FAILURE'

export const QUOTES_SAVE_REQUEST = 'QUOTES_SAVE_REQUEST'
export const QUOTES_SAVE_SUCCESS = 'QUOTES_SAVE_SUCCESS'
export const QUOTES_SAVE_FAILURE = 'QUOTES_SAVE_FAILURE'

export const QUOTES_CANCEL_REQUEST = 'QUOTES_CANCEL_REQUEST'
export const QUOTES_CANCEL_SUCCESS = 'QUOTES_CANCEL_SUCCESS'
export const QUOTES_CANCEL_FAILURE = 'QUOTES_CANCEL_FAILURE'

export const QUOTES_MAKE_ORDER_REQUEST = 'QUOTES_MAKE_ORDER_REQUEST'
export const QUOTES_MAKE_ORDER_SUCCESS = 'QUOTES_MAKE_ORDER_SUCCESS'
export const QUOTES_MAKE_ORDER_FAILURE = 'QUOTES_MAKE_ORDER_FAILURE'

export const EMPTY_QUOTES = 'EMPTY_QUOTES'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
