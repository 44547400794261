<template>
  <b-container>
    <b-row class="mb-3">
      <b-col cols="12" lg="6">
        <h1 class="font-weight-bold text-primary">View Quote</h1>
        <small v-if="quote.cancelled_at" class="text-danger">
          Cancelled At:{{ moment(quote.cancelled_at).format("lll") }}
        </small>
      </b-col>

      <b-col cols="12" lg="6">
        <div class="mt-3 mt-md-1 d-md-flex flex-row justify-content-end pt-2">
          <b-button v-b-hover.bottom="'Edit this quote'"
                    :to="{name: 'dash.quotes.update', params: { id: quote.id }}"
                    class="btn mr-1 mb-0 btn-primary" variant="primary">
            <b-icon class="mr-1" icon="pen"/>
            Edit Quote
          </b-button>

          <b-button v-if="!quote.cancelled_at" v-b-hover.bottom="'Cancel this order.'" class="mr-1 mb-0"
                    variant="danger"
                    @click="confirmCancel">
            <b-icon icon="x"/>
            Cancel
          </b-button>
          <div class="confirmCancel">
            <b-modal id="confirmCancelModal" :title="'Cancel Quote'" hide-footer>
              <p>Are you sure you want to cancel?</p>
              <div class="flex flex-column text-center">
                <button class="btn btn-danger w-25 mr-4" @click="doCancelQuote">Yes</button>
                <button class="btn btn-light w-25" @click="dismissModal">No</button>
              </div>
            </b-modal>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="isLoading">
      <b-col cols="12">
        <p class="text-center">
          <b-spinner variant="secondary"></b-spinner>
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!isLoading">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'user_id')" label="Staff Member:">
              <b-form-input v-model="quote.user.name"
                            :state="validationState(errors.errors, 'user_id')" disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback=" validationInvalidFeedback(errors.errors,'customer_id')" label="Customer:">
              <b-input-group>
                <b-form-input v-model="quote.customer.name"
                              :state="validationState(errors.errors,'customer_id')" disabled/>
                <b-input-group-append>
                  <b-button v-b-hover.bottom="'View this customer.'" variant="info" @click="viewQuoteCustomer">
                    View Customer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors, 'id')" label="Quote ID:">
              <b-form-input v-model="quote.id"
                            :state="validationState(errors.errors, 'id')" disabled/>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'quote_date')" label="Quote Date:">
              <b-form-datepicker id="quote_date"
                                 v-model="quote.quote_date"
                                 :state="validationState(errors.errors, 'quote_date')"
                                 class="mb-2" disabled>
              </b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Quote Uploads -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                       @click.prevent="toggleCollapse('order_uploads')">
                  <b-icon :icon="collapsed['order_uploads'] ? 'chevron-right' : 'chevron-down' "
                          class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Uploads</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_uploads']" class="mt-4">
                <b-col v-if="!quote.order_uploads.length" cols="12">
                  <b-col class="text-center" cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no uploaded files.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="quote.order_uploads.length" cols="12">
                  <b-card>
                    <b-col v-for="order_upload in quote.order_uploads" :key="order_upload.id">
                      <b-card class="mb-4">
                        <b-card-text>
                          <b-col class="d-flex justify-content-start" cols="12" md="12">
                            Document Name:{{ order_upload.document.filename }}
                          </b-col>
                        </b-card-text>
                        <b-card-text>
                          <b-col class="d-flex justify-content-start" cols="12" md="12">
                            Uploaded:{{ moment(order_upload.created_at).format("Do MMM YYYY") }}
                          </b-col>
                        </b-card-text>
                        <b-card-text>
                          <b-col class="d-flex justify-content-start" cols="12" md="12">
                            <b-button v-b-hover.bottom="'View this document.' " class="mr-1 mb-0" variant="secondary">
                              <b-icon icon="eye"/>
                              <a :href="order_upload.document.url" class="text-white" target="_blank">View</a>
                            </b-button>
                          </b-col>
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-card>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Quote Items -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row mb-4" cols="12" md="9" role="button"
                       @click.prevent="toggleCollapse('order_items')">
                  <b-icon :icon="collapsed['order_items'] ? 'chevron-right' : 'chevron-down' "
                          class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-2">Items</p>
                  </div>
                </b-col>
                <b-col class="d-flex flex-column justify-content-end mb-2" cols="12" md="6">
                  <b-button v-b-hover.bottom="'Fitting Total'" variant="success">
                    Total Fitting: {{ computedFittingTotal| currencyFormat }}
                  </b-button>
                </b-col>
                <b-col class="d-flex flex-column justify-content-end mb-2" cols="12" md="6">
                  <b-button v-b-hover.bottom="'Order Total'" variant="primary">
                    Total Inc Vat: {{ computedQuoteTotal | currencyFormat }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_items']" class="mt-4">
                <b-col v-if="!quote.order_items.length" cols="12">
                  <b-col cols="12">
                    <b-card class="py-3 text-center">
                      <p class="mb-4">
                        <fa-icon class="display-1 text-dark" icon="times"></fa-icon>
                      </p>
                      <p class="font-weight-normal h5 mb-0 text-dark">There are no quotes.</p>
                    </b-card>
                  </b-col>
                </b-col>
                <b-col v-if="quote.order_items.length" cols="12">
                  <b-table-simple class="overflow-auto" hover striped>
                    <b-thead>
                      <b-tr>
                        <b-th>Product</b-th>
                        <b-th>Quantity</b-th>
                        <b-th>Price</b-th>
                        <b-th>Discounts</b-th>
                        <b-th>Fitting Date</b-th>
                        <b-th>Staircase?</b-th>
                        <b-th>Fitting Cost</b-th>
                      </b-tr>
                    </b-thead>

                    <b-tbody>
                      <b-tr v-for="(item, ind) in quote.order_items" :key="ind">
                        <td>
                          <p class="mb-0">{{ item.name }}</p>
                          <small v-if="!(item.product && item.product.range)" class="text-muted">No
                            Range</small>
                          <small v-else class="text-muted">Range: {{ item.product.range }}</small>
                          <p><small v-if="item.measurement_types_id === 1" class="text-muted">
                            {{ item.length }} X {{ item.width }}</small>
                            <small v-else-if="item.measurement_types_id === 2" class="text-muted">Packs:
                              {{ item.quantity }}</small>
                            <small v-else class="text-muted">Items: {{ item.quantity }}</small>
                          </p>
                          <p><small v-if="item.deleted_at" class="text-muted">Deleted
                            {{ moment(item.deleted_at).format("Do MMM YYYY") }}</small></p>
                        </td>

                        <td v-if="item.measurement_types_id === 1 && !item.deleted_at">{{ item.quantity }}
                        </td>
                        <td v-else>{{ item.quantity }}</td>

                        <td>{{ item.total_price| currencyFormat }}</td>
                        <td>{{ item.discount | currencyFormat }}</td>
                        <td>
                          <div class="d-flex flex-fill flex-column">
                            <div v-show="!item.deleted_at">
                              <div v-if="!item.fitting_required">
                                <small>No Fitting Required</small>
                              </div>
                              <div v-else>
                                <small v-if="item.fitting_date">
                                  {{ moment(item.fitting_date).format("Do MMM YYYY") }}
                                </small>
                                <small v-else>No Fitting Date</small>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>{{ item.staircase ? "Yes" : "No" }}</td>

                        <td v-if="item.fitting_required">
                          <div v-if="!item.fitting_cost_exc_vat" class="d-flex flex-column">
                            {{ item.fitting_cost_inc_vat| currencyFormat }}
                            <small class="text-muted">Invoice</small>
                          </div>
                          <div v-else-if="!item.fitting_cost_inc_vat">
                            {{ item.fitting_cost_exc_vat| currencyFormat }}
                            <small class="text-muted">Direct</small>
                          </div>
                          <div v-else>No Fitting Cost</div>
                        </td>
                        <td v-else>
                          <small class="text-muted">
                            No Fitting Required
                          </small>
                        </td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Order Notes -->
        <b-row class="mb-4">
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col class="d-flex flex-row" cols="12" md="6" role="button"
                       @click.prevent="toggleCollapse('order_notes')">
                  <b-icon :icon=" collapsed['order_notes'] ? 'chevron-right' : 'chevron-down' "
                          class="align-self-center cursor-pointer text-muted" font-scale="1.5"></b-icon>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <p class="h4 font-weight-bold mb-0">Quote Notes</p>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="!collapsed['order_notes']" class="mt-4">
                <b-col cols="12">
                  <b-form-group :invalid-feedback="validationInvalidFeedback(errors.errors,'newNote')"
                                label="Create a note for this quote">
                    <b-form-textarea v-model="newNote" :state="validationState( errors.errors,'newNote')"
                                     placeholder="Insert note here..."
                                     size="sm">
                    </b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button variant="primary" @click="addNote">Add Note</b-button>
                </b-col>

                <!-- Add New Note Field -->
                <b-col v-if="quote.order_notes.length" cols="12">
                  <hr/>
                  <b-row v-for="(note,noteInd) in quote.order_notes" :key="noteInd">
                    <b-col
                      :invalid-feedback="validationInvalidFeedback(errors.errors,'order_notes.' + noteInd + '.note')"
                      cols="12">
                      <b-card class="p-4 mt-3" no-body>
                        <p class="note">{{ note.note }}</p>
                        <hr/>
                        <b-row>
                          <b-col class="d-flex flex-row justify-content-start align-items-center"
                                 cols="9">
                            <p class="note-created">
                              Left by: {{ note.user.name }}
                            </p>
                          </b-col>
                          <b-col class="d-flex flex-row justify-content-end align-items-center"
                                 cols="3">
                            <p class="note-created text-muted">
                              <FriendlyDate :date="note.created_at"></FriendlyDate>
                            </p>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import validation from "../../mixins/validation";
import {mapActions, mapGetters} from "vuex";
import FriendlyDate from "../../components/FriendlyDate";
import momentMixin from "../../mixins/momentMixin";

export default {
  mixins: [validation, momentMixin],
  components: {
    FriendlyDate,
  },
  created() {
    if (this.$route.params.id) {
      this.fetch(this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters("quotes", ["errors", "isLoading", "isSaving", "single"]),

    computedFittingTotal() {
      let fittingTotal = 0;
      this.quote.order_items.forEach((item) => {
        if (!Object.keys(item).includes("deleted_at") || !item.deleted_at) {
          if (!isNaN(item.fitting_cost_inc_vat)) {
            fittingTotal += Number(item.fitting_cost_inc_vat);
          }
          if (!isNaN(item.fitting_cost_exc_vat)) {
            fittingTotal += Number(item.fitting_cost_exc_vat);
          }
        }
      });
      return Number(fittingTotal).toFixed(2);
    },
    computedQuoteTotal() {
      let quoteTotal = 0;
      this.quote.order_items.forEach((item) => {
        if (!item.deleted_at) {
          if (
            !isNaN(item.sale_price) &&
            !isNaN(item.quantity) &&
            !isNaN(item.fitting_cost_inc_vat)
          ) {
            let itemTotal =
              Number(item.sale_price) * Number(item.quantity) +
              Number(item.fitting_cost_inc_vat) -
              Number(item.discount);
            quoteTotal += Number(itemTotal);
          }
        }
      });
      return quoteTotal;
    },
  },

  data() {
    return {
      collapsed: {
        order_items: !this.$route.params.id,
        order_notes: true,
        order_uploads: true,
      },
      newNote: null,
      quote: {
        id: null,
        user_id: null,
        user: {},
        customer_id: null,
        customer: {},
        quote: null,
        quote_date: null,
        quote_source: null,
        order_items_id: null,
        order_items: [],
        supplier_id: null,
        supplier: {},
        order_notes: [],
        users: [],
        orders: [],
        order_uploads: [],
        uploads: [],
        product_id: null,
        products: [],
        fitting_cost_inc_vat: null,
        cancelled_at: null,
      },
    };
  },

  methods: {
    ...mapActions("quotes", ["fetch", "save", "cancelQuote", "doMakeOrder"]),

    addNote() {
      window.axios
        .post(`/quotes/${this.$route.params.id}/add-note`, {
          note: this.newNote,
        })
        .then((response) => {
          this.quote.order_notes.unshift(response.data);
        });
    },

    toggleCollapse(name) {
      this.collapsed[name] = !this.collapsed[name];
    },

    viewQuoteCustomer() {
      this.$router.push({
        name: "dash.customers.view",
        params: {id: this.quote.customer.id},
      });
    },

    returnFileUrl() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.quote.document && this.quote.document.hasOwnProperty("url")) {
        return this.quote.document.url;
      }
    },

    doCancelQuote() {
      this.cancelQuote(this.quote.id).then((response) => {
        this.fetch(this.quote.id);
        this.dismissModal();
      });
    },

    async confirmCancel() {
      this.$bvModal.show('confirmCancelModal');
    },

    dismissModal() {
      this.$bvModal.hide('confirmCancelModal')
    },
  },
  watch: {
    single() {
      this.quote = this.single;
    },
  },
  filters: {
    currencyFormat: (v) =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(v),
  },
};
</script>

<style lang="scss">
.danger {
  background: red;
  color: white;
}
</style>
