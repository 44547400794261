<template>
  <router-view />
</template>

<script>
import quoteModule from '../../store/quotes'
import userModule from '../../store/users'
import customerModule from '../../store/customers'
import productModule from '../../store/products'
import noteModule from '../../store/notes'
import supplierModule from "../../store/suppliers"

export default {
  beforeCreate () {
    this.$store.registerModule('quotes', quoteModule)
    this.$store.registerModule('users', userModule)
    this.$store.registerModule('customers', customerModule)
    this.$store.registerModule('products', productModule)
    this.$store.registerModule('notes', noteModule)
    this.$store.registerModule("suppliers", supplierModule)
  },
  destroyed () {
    this.$store.unregisterModule('quotes')
    this.$store.unregisterModule('users')
    this.$store.unregisterModule('customers')
    this.$store.unregisterModule('products')
    this.$store.unregisterModule('notes')
    this.$store.unregisterModule("suppliers")
  }
}
</script>
